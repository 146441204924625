import { featureCollection, point } from '@turf/turf';
import { FeatureCollection } from 'geojson';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers';
import { GEOLOCATION_LAYER_NAMES } from '../consts';
import { useGeolocationContext } from '../geolocation-context';
import { GeolocationAsset, GeolocationTask } from '../geolocation-task';
import { GeolocatedLabel, LabelInstance, MetadataLabel } from '../model';
import { useGeolocationMap } from './use-geolocation-map';

type SupportedLabels = MetadataLabel | GeolocatedLabel;

type MinState = { clientID?: number | null | undefined; frame: number; objectType: string };
type GetGeoJSONArgs<T extends SupportedLabels, S extends MinState> = {
    data: LabelInstance<T, S>[];
    getProps: (x: LabelInstance<T, S>) => Record<string, any>;
};

function toFeatures<T extends SupportedLabels, S extends MinState>({ data, getProps }: GetGeoJSONArgs<T, S>) {
    return data.flatMap((d) => {
        if (!d.values.location) return [];
        return point([d.values.location.lon, d.values.location.lat], getProps(d), {
            id: d.state.clientID ?? undefined,
        });
    });
}

function assetsToFeatures(assets: LabelInstance<GeolocatedLabel, MinState>[]) {
    return toFeatures({
        data: assets,
        getProps: (d) => {
            const { location, ...props } = d.values;
            return {
                ...props,
                color: d.label.color,
                label: d.label.name,
                frame: d.state.frame,
                clientID: d.state.clientID,
                objectType: d.state.objectType,
            };
        },
    });
}

// Create empty GeoJSON feature collection
const createEmptyFeatureCollection = (): FeatureCollection => ({
    type: 'FeatureCollection',
    features: [],
});

// This hook manages layer data and handles modifications
export const useGeolocationLayers = (task: GeolocationTask) => {
    const map = useGeolocationMap();
    const { isMapStyleLoaded } = useGeolocationContext();

    // Track complete layer and modified layer data
    const [cameraLayer, setCameraLayer] = useState<FeatureCollection>(createEmptyFeatureCollection());
    const [completeAssetsLayer, setCompleteLayer] = useState<FeatureCollection>(createEmptyFeatureCollection());
    const [modifiedAssetsLayer, setModifiedLayer] = useState<FeatureCollection>(createEmptyFeatureCollection());
    const modifiedAssetsRef = useRef<Map<number, GeolocationAsset>>(new Map());

    const uploading = useSelector((state: CombinedState) => state.annotation.annotations.saving.uploading);
    const updateModifiedFeatureState = useMemo(
        () => (id: number | null | undefined | string, modified: boolean) => {
            if (!map.current) return;

            const identifier = { source: GEOLOCATION_LAYER_NAMES.assets, id: id ?? undefined };

            try {
                if (modified) {
                    map.current?.setFeatureState(identifier, { modified: true });
                } else {
                    map.current?.removeFeatureState(identifier, 'modified');
                }
            } catch (e) {
                console.error('Failed to clear feature state', e);
            }
        },
        [map],
    );

    const rebaseLayers = useMemo(
        () => async () => {
            console.debug('[rebaseLayers] Computing layers from task');
            const { camera, ...assetLayers } = await task.computeLayers();

            const assetsGeojson = featureCollection(Object.values(assetLayers).flatMap(assetsToFeatures));

            const cameraGeojson = featureCollection(
                toFeatures({
                    data: camera,
                    getProps: (d) => ({
                        color: d.label.color,
                        frame: d.state.frame,
                        clientID: d.state.clientID,
                        bearing: d.values.fov?.bearing,
                    }),
                }),
            );

            setCameraLayer(cameraGeojson);
            setCompleteLayer(assetsGeojson);
            setModifiedLayer(createEmptyFeatureCollection());

            if (map.current) {
                assetsGeojson.features.forEach((feature) => {
                    updateModifiedFeatureState(feature.id, false);
                });
            }
            modifiedAssetsRef.current.clear();

            return { cameraGeojson, assetsGeojson };
        },
        [task, map, updateModifiedFeatureState],
    );

    // Rebase layers on mount or after saving
    useEffect(() => {
        console.debug('[Rebase Layers Effect] Starting effect');
        const runRebaseLayers = async () => {
            try {
                const start = performance.now();
                await rebaseLayers();
                const end = performance.now();
                console.debug('[Rebase Layers Effect] Layers rebased successfully in', end - start, 'ms');
            } catch (error) {
                console.error('[Rebase Layers Effect] Failed to rebase layers', error);
            }
        };

        if (!isMapStyleLoaded) {
            console.debug('[Rebase Layers Effect] Style not loaded yet, skipping rebase');
            return;
        }

        if (uploading) {
            console.debug('[Rebase Layers Effect] Uploading, skipping rebase');
            return;
        }

        console.debug('[Rebase Layers Effect] Rebasing layers');
        runRebaseLayers();
    }, [isMapStyleLoaded, rebaseLayers, uploading]);

    // Track changes to assets and update modified layer. Any annotation that
    // the user has visited and modified will be shown in the modified layer.
    useEffect(() => {
        console.debug('[Track Assets Effect] Checking for asset modifications');
        if (!map.current || !isMapStyleLoaded) {
            console.debug('[Track Assets Effect] Early return: Map not ready or style not loaded');
            return;
        }

        const modifiedAssets = modifiedAssetsRef.current;
        const currentAssets = task.assets || [];
        console.debug(`[Track Assets Effect] Processing ${currentAssets.length} assets`);

        let didUpdate = false;
        for (const asset of currentAssets) {
            if (!asset.id || !asset.location) {
                console.debug('[Track Assets Effect] Skipping asset with missing id or location', asset);
                continue;
            }

            const existingAsset = modifiedAssets.get(asset.id);
            // Case 1: New asset. Add to modified if update flag includes attributes
            const isNewModifiedAsset = !existingAsset;
            // Case 2: Existing asset. Check if it has been modified
            const isExistingModifiedAsset =
                existingAsset && !isEqual(existingAsset.instance.values, asset.instance.values);
            const isModified = isNewModifiedAsset || isExistingModifiedAsset;

            console.debug(
                `[Track Assets Effect] Asset ${asset.id} modified: ${isModified} (new: ${isNewModifiedAsset}, existing: ${isExistingModifiedAsset})`,
            );
            if (isModified) {
                modifiedAssets.set(asset.id, asset);
                updateModifiedFeatureState(asset.id, true);
                didUpdate = true;
            }
        }

        // Update modified layer if there are changes
        if (didUpdate) {
            console.debug('[Track Assets Effect] Updates detected, refreshing modified layer');
            // Update the modified layer with new features
            const modifiedFeatures = assetsToFeatures(Array.from(modifiedAssets.values()).map((a) => a.instance));
            setModifiedLayer(featureCollection(modifiedFeatures));
        } else {
            console.debug('[Track Assets Effect] No updates detected');
        }
    }, [task.assets, map, updateModifiedFeatureState, isMapStyleLoaded]);

    return { cameraLayer, completeLayer: completeAssetsLayer, modifiedLayer: modifiedAssetsLayer };
};
