import { z } from 'zod';

import { clone, featureCollection } from '@turf/turf';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { MapLayerMouseEvent, MapboxGeoJSONFeature } from 'mapbox-gl';
import { Attribute } from './cvat';
import type {
    TypedAttributeDefinition,
    TypedAttributeMap,
    TypedAttributeValues,
    TypedLabel,
    TypedLabelAny,
    TypedMapForLabel,
} from './typed-attribute';

export const parseJSONObjectOrNull = (raw: unknown) => {
    if (typeof raw !== 'string' || raw.length === 0) return null;
    try {
        const parsed = JSON.parse(raw);
        if (typeof parsed !== 'object') return null;
        return parsed;
    } catch (e) {
        return null;
    }
};

export const findTypedAttribute = <T extends TypedAttributeDefinition>(
    attributes: Attribute[],
    name: string,
    Type: T,
) =>
    mapFirst(attributes, (attr) => {
        const parsed = Type.Attribute.safeParse(attr);
        if (parsed.success && parsed.data.name.name === name) {
            return { success: true, data: { ...parsed.data, schema: Type.Value as T['Value'] } };
        }
        const error = new z.ZodError([]);
        error.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Attribute ${name}: ${Type.Name} is required`,
            path: [name],
        });
        return { success: false, error };
    });

/**
 * Returns the first successful result of applying `fn` to each element of `inputs`, or a merged zod error if all failed.
 */
export const mapFirst = <I, O>(inputs: I[], fn: (i: I) => z.SafeParseReturnType<I, O>): z.SafeParseReturnType<I, O> => {
    const error = new z.ZodError([]);
    for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        const result = fn(input);
        if (result.success) return result;
        error.addIssue({
            code: z.ZodIssueCode.custom,
            message: result.error.message,
            path: [i],
        });
    }
    return { success: false, error };
};

export type LabelInstanceAdapter<S> = {
    hasLabel: (state: S, labelId: number) => boolean;
    getAttribute: (state: S, attrId: number) => string | null | undefined;
};

export type LabelInstance<L extends TypedLabelAny, S, R extends TypedAttributeMap = TypedMapForLabel<L>> = {
    label: TypedLabel<R>;
    state: S;
    values: TypedAttributeValues<R>;
};

export const extractLabelInstances = <L extends TypedLabelAny, S>(
    states: S[],
    label: L,
    adapter: LabelInstanceAdapter<S>,
): LabelInstance<L, S>[] => {
    // Get all annotations for this label
    const matchingStates = states.filter((s) => adapter.hasLabel(s, label.id));
    const values: LabelInstance<L, S>[] = [];
    for (const state of matchingStates) {
        const labelState: any = { label, state, values: {} };
        // For each typed attribute of the label, get the value from the annotation
        for (const [name, { id, schema }] of Object.entries(label.types)) {
            labelState.values[name] = undefined;
            const rawObject = parseJSONObjectOrNull(adapter.getAttribute(state, id));
            if (rawObject) {
                const parsed = schema.safeParse(rawObject);
                if (parsed.success) {
                    labelState.values[name] = parsed.data;
                } else {
                    console.debug('Failed to parse attribute', name, parsed.error);
                }
            }
        }
        values.push(labelState);
    }
    return values;
};

export type MapFeature<P = GeoJsonProperties, Source extends string = string> = {
    feature: FeatureWithSource<P, Source>;
    x: number;
    y: number;
};

export type FeatureWithSource<
    P = GeoJsonProperties,
    Source extends string = string,
    G extends Geometry = Geometry,
> = Feature<G, P> & { source: Source; sourceLayer?: string };

export const cloneFeature = (f: FeatureWithSource | MapboxGeoJSONFeature): FeatureWithSource => {
    const { type, geometry, properties, source, id, bbox, sourceLayer } = f;
    return clone({ type, geometry, properties, source, id, bbox, sourceLayer } as any);
};

export const getFeaturesFromEvent = (event: MapLayerMouseEvent) => {
    const {
        features,
        point: { x, y },
    } = event;

    if (features) return { features: features.map(cloneFeature), x, y };
};

export const emptyFeatures: FeatureCollection = featureCollection([]);
