import { updateAnnotationsAsync } from 'actions/annotation-actions';
import { ObjectState } from 'cvat-core-wrapper';
import { z } from 'zod';
import { LIDAR_RELATED_IMAGE_COLORS } from './consts';
import { CyvlTask } from './cyvl-task';
import { Job, Label, LidarMetadataLabel, LidarRelatedImage, extractLabelInstances, mapFirst } from './model';

export type RelatedImagery = ReturnType<LidarAnnotationTask['computeRelatedImagery']>;
export type RelatedImage = NonNullable<RelatedImagery>['images'][number];

const STATE_CHANGED = 'internalStateChanged';

export class LidarAnnotationTask extends CyvlTask {
    static parse = (jobRaw: unknown): LidarAnnotationTask => {
        const task = LidarAnnotationTask.safeParse(jobRaw);
        if (!task.success) throw task.error;
        return task.data;
    };

    static safeParse = (jobRaw: unknown): z.SafeParseReturnType<any, LidarAnnotationTask> => {
        const job = Job.safeParse(jobRaw);
        if (!job.success) return job;

        const { labels } = job.data;

        const metadata = mapFirst(labels, LidarMetadataLabel.safeParse);
        if (!metadata.success) return metadata;

        return { success: true, data: new LidarAnnotationTask(labels, metadata.data) };
    };

    relatedImagery!: RelatedImagery;
    private events = new EventTarget();
    private frameNumber = -1;
    private visibility: Record<string, boolean> = {};

    constructor(readonly labels: Label[], readonly metadataLabel: LidarMetadataLabel) {
        super(labels);
    }

    addChangeListener(listener: () => void) {
        this.events.addEventListener(STATE_CHANGED, listener);
    }

    removeChangeListener(listener: () => void) {
        this.events.removeEventListener(STATE_CHANGED, listener);
    }

    private onStateChanged() {
        this.onUpdate();
        this.events.dispatchEvent(new Event(STATE_CHANGED));
    }

    protected onUpdate(): void {
        const currentFrameNumber = this.states[0]?.frame;
        if (currentFrameNumber !== this.frameNumber) {
            this.visibility = {};
            this.frameNumber = currentFrameNumber;
        }
        this.relatedImagery = this.computeRelatedImagery();
    }

    private computeRelatedImagery() {
        const meta = this.getFrameObjects(this.metadataLabel)[0];
        if (!meta) return null;
        const images =
            parseImagery(this.states, this.metadataLabel)?.map((v, i) => {
                const name = generateImageName(v.url, i);
                return {
                    ...v,
                    name,
                    color: LIDAR_RELATED_IMAGE_COLORS[i % LIDAR_RELATED_IMAGE_COLORS.length],
                    isVisible: this.visibility[name] ?? true,
                    setVisibility: (isVisible: boolean) => {
                        this.visibility[name] = isVisible;
                        this.onStateChanged();
                    },
                };
            }) ?? [];

        return {
            images,
            isLocked: meta.state.lock,
            lock: () => {
                meta.state.lock = true;
                this.dispatch(updateAnnotationsAsync([meta.state]));
            },
        };
    }

    async getRelatedImagery(): Promise<Record<string, ImageBitmap>> {
        const images = this.relatedImagery?.images;
        const data: Record<string, ImageBitmap> = {};

        if (!images) return data;

        async function createImageBitmapFromUrl(url: string): Promise<ImageBitmap> {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Failed to fetch image from URL: ${url}`);
            }
            const blob = await response.blob();
            return createImageBitmap(blob);
        }

        let i = 0;
        for (const { url, name } of images) {
            try {
                const bitmap = await createImageBitmapFromUrl(url);
                data[name] = bitmap;
            } catch (err) {
                console.error(`Failed to load or create ImageBitmap for URL: ${url}`, err);
            }
            i++;
        }

        return data;
    }

    getRelatedImage(name: string): RelatedImage | undefined {
        return this.relatedImagery?.images.find((v) => v.name === name);
    }
}

export const generateImageName = (url: string, index: number): string => {
    const urlObj = new URL(url);
    // Extract last path segment as image name
    return `${index + 1}_${urlObj.pathname.split('/').pop() || 'Image'}`;
};

export const parseImagery = (states: ObjectState[], label?: LidarMetadataLabel): LidarRelatedImage[] | undefined => {
    if (!label) return;
    const meta = extractLabelInstances(states, label, {
        getAttribute: (s, id) => s.attributes[id],
        hasLabel: (s, id) => s.label.id === id,
    })[0];
    if (!meta) return;
    const imagery = meta.values;
    return Object.values(imagery).filter((v): v is LidarRelatedImage => !!v);
};
