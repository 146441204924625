import * as turf from '@turf/turf';
import { activateObject, updateAnnotationsAsync } from 'actions/annotation-actions';
import { RawCollection } from 'cvat-core/src/annotations-collection';
import { RawShapeData, RawTagData, RawTrackData } from 'cvat-core/src/annotations-objects';
import { z } from 'zod';
import { CyvlTask } from './cyvl-task';
import {
    Coordinates,
    GeolocatedLabel,
    Job,
    Label,
    LabelInstance,
    LabelInstanceAdapter,
    MetadataLabel,
    extractLabelInstances,
    mapFirst,
} from './model';

const GREENTOWN_LABS = {
    latitude: 42.3820702,
    longitude: -71.1026937,
    zoom: 20,
};

export type GeolocationAsset = ReturnType<GeolocationTask['computeAssets']>[number];
export type Camera = NonNullable<ReturnType<GeolocationTask['computeCamera']>>;

export type RawObjectData =
    | (RawTagData & { objectType: 'tag' })
    | (RawShapeData & { objectType: 'shape' })
    | (RawTrackData & { objectType: 'track' });

export type GeolocationLabelInstance = LabelInstance<GeolocatedLabel, RawObjectData>;
export type GeolocationMapLayers = {
    camera: LabelInstance<MetadataLabel, RawObjectData & { objectType: 'tag' }>[];
    [key: string]: GeolocationLabelInstance[];
};

export class GeolocationTask extends CyvlTask {
    static parse = (jobRaw: unknown): GeolocationTask => {
        const task = GeolocationTask.safeParse(jobRaw);
        if (!task.success) throw task.error;
        return task.data;
    };

    static safeParse = (jobRaw: unknown): z.SafeParseReturnType<any, GeolocationTask> => {
        const job = Job.safeParse(jobRaw);
        if (!job.success) return job;

        const { labels } = job.data;

        const metadata = mapFirst(labels, MetadataLabel.safeParse);
        if (!metadata.success) return metadata;

        const geolocatedLabels = labels.flatMap((l) => {
            const parsed = GeolocatedLabel.safeParse(l);
            return parsed.success && parsed.data.name !== metadata.data.name ? [parsed.data] : [];
        });

        return { success: true, data: new GeolocationTask(labels, metadata.data, geolocatedLabels) };
    };

    constructor(
        readonly labels: Label[],
        readonly metadataLabel: MetadataLabel,
        readonly geolocatedLabels: GeolocatedLabel[],
    ) {
        super(labels);
    }

    camera?: Camera;
    assets!: GeolocationAsset[];
    bounds!: [number, number, number, number];
    defaultViewState!: ReturnType<typeof this.computeDefaultViewState>;
    defaultLocation!: ReturnType<typeof this.computeDefaultLocation>;

    onUpdate() {
        this.camera = this.computeCamera();
        this.assets = this.computeAssets();
        this.defaultViewState = this.computeDefaultViewState();
        this.defaultLocation = this.computeDefaultLocation();
        this.bounds = this.computeBounds();
    }

    /** Compute the bounding box of the camera, fov, and all assets using turf */
    private computeBounds() {
        const camera = this.camera?.location ? turf.point([this.camera.location.lon, this.camera.location.lat]) : null;
        const assets = this.assets.map((a) => a.location && turf.point([a.location.lon, a.location.lat]));
        let fov: turf.Feature[] = [];
        if (camera && this.camera?.fov) {
            const { far, bearing: ccwEBearing, fov: angle } = this.camera.fov;

            const bearing = convertBearing(ccwEBearing);
            const center = turf.destination(camera, (far / 2) * 1e-3, bearing);
            const sin = Math.sin(angle / 2);
            const diag = far * Math.sqrt(0.25 + sin * sin) * 1e-3;
            fov = [
                turf.destination(center, diag, normalizeBearing(bearing + 45)),
                turf.destination(center, diag, normalizeBearing(bearing + 135)),
                turf.destination(center, diag, normalizeBearing(bearing + 225)),
                turf.destination(center, diag, normalizeBearing(bearing + 315)),
            ];
        }

        const features = [camera, ...assets, ...fov].filter((f): f is turf.Feature => !!f);

        const bounds = turf.bbox(
            turf.featureCollection(
                features.length > 0 ? features : [turf.point([GREENTOWN_LABS.longitude, GREENTOWN_LABS.latitude])],
            ),
        );
        return bounds as [number, number, number, number];
    }

    private computeCamera() {
        const meta = this.getFrameObjects(this.metadataLabel)[0];
        if (!meta) return;
        const { fov } = meta.values;
        return {
            location: meta.values.location,
            fov: fov && fov.bearing !== 0 ? fov : undefined,
            active: meta.state.clientID === this.activatedStateID,
            color: meta.label.color,
            frame: meta.state.frame,
            id: meta.state.clientID,
            onHover: () => {
                this.dispatch(activateObject(meta.state.clientID, null, null));
            },
            isLocked: meta.state.lock,
            lock: () => {
                meta.state.lock = true;
                this.dispatch(updateAnnotationsAsync([meta.state]));
            },
            updateLocation: (location: Coordinates) => {
                // const s = this.states.find((s) => s.clientID === meta.state.clientID);
                // if (!s) return;
                // To update an attribute, you must assign a new object to the attributes property
                // https://github.com/roadgnar/cvat/blob/636dc6a58e279990088d3b6a7f7f66452c2ecc43/cvat-core/src/object-state.ts#L422
                const updated = { ...meta.state.attributes };
                updated[meta.label.types.location.id] = JSON.stringify(location);
                meta.state.attributes = updated;
                this.dispatch(updateAnnotationsAsync([meta.state]));
            },
        };
    }

    private computeAssets() {
        const assets = this.geolocatedLabels.flatMap(this.getFrameObjects);

        return assets.map((a) => ({
            instance: a,
            location: a.values.location,
            color: a.label.color,
            active: a.state.clientID === this.activatedStateID,
            id: a.state.clientID,
            onSelect: () => {
                this.dispatch(activateObject(a.state.clientID, null, null));
            },
            update: (location: Coordinates) => {
                // const s = this.states.find((s) => s.clientID === a.state.clientID);
                // if (!s) return;
                const updated = { ...a.state.attributes };
                updated[a.label.types.location.id] = JSON.stringify(location);
                a.state.attributes = updated;
                this.dispatch(updateAnnotationsAsync([a.state]));
            },
        }));
    }

    private computeDefaultViewState() {
        return this.camera?.location
            ? {
                  longitude: this.camera.location.lon,
                  latitude: this.camera.location.lat,
                  zoom: 20,
              }
            : GREENTOWN_LABS;
    }

    private computeDefaultLocation(): Coordinates {
        const viewState = this.defaultViewState;
        return { lat: viewState.latitude, lon: viewState.longitude };
    }

    async computeLayers(): Promise<GeolocationMapLayers> {
        const start = performance.now();
        const anno: RawCollection = await this.job.annotations.export();
        const tags = anno.tags.map((t) => ({ ...t, objectType: 'tag' as const }));
        const rawObjects: RawObjectData[] = [
            ...tags,
            ...anno.shapes.map((s) => ({ ...s, objectType: 'shape' as const })),
            ...anno.tracks.map((t) => ({ ...t, objectType: 'track' as const })),
        ];

        const adapter: LabelInstanceAdapter<RawObjectData> = {
            getAttribute: (s, id) => s.attributes.find((a) => a.spec_id === id)?.value,
            hasLabel: (s, id) => s.label_id === id,
        };

        const layers: GeolocationMapLayers = {
            camera: extractLabelInstances(tags, this.metadataLabel, adapter),
            ...Object.fromEntries(
                this.geolocatedLabels.map((label) => [label.name, extractLabelInstances(rawObjects, label, adapter)]),
            ),
        };
        const end = performance.now();
        console.debug('computeLayers took', end - start, 'ms');
        return layers;
    }
}

function convertBearing(bearingCCWEast: number) {
    const bearingCWNorth = 90 - bearingCCWEast;
    return normalizeBearing(bearingCWNorth);
}

function normalizeBearing(bearingCWNorth: number) {
    let bearing = bearingCWNorth % 360;
    // Normalize using modulo and adjusting for negative remainder
    if (bearing <= -180) {
        bearing += 360;
    } else if (bearing > 180) {
        bearing -= 360;
    }

    return bearing;
}
