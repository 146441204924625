import { explode } from '@turf/turf';
import useThrottledCallback from 'beautiful-react-hooks/useThrottledCallback';
import { isEqual, min, set, sortBy } from 'lodash';
import { FeatureIdentifier, MapboxGeoJSONFeature } from 'mapbox-gl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MapLayerMouseEvent, MapRef } from 'react-map-gl';
import { isGeolocationLayerName } from './consts';
import { RefToMap, useGeolocationMap } from './hooks';
import { MapFeature, cloneFeature, getFeaturesFromEvent } from './model';

const l2Distance = ([x1, y1]: number[], [x2, y2]: number[]) => {
    const dx = x2 - x1,
        dy = y2 - y1;
    return Math.sqrt(dx * dx + dy * dy);
};

export const findNearestFeatures = ({
    map,
    x,
    y,
    layers,
    filter,
    size = 20,
}: {
    map: MapRef;
    x: number;
    y: number;
    layers: string[];
    filter?: any[];
    size?: number;
}) => {
    const cursor = [x, y];
    const result = map.queryRenderedFeatures(
        [
            [x - size, y - size],
            [x + size, y + size],
        ],
        { layers, filter },
    );
    return sortBy(result, (f) => {
        const points = explode(f as any).features.map((f) => {
            const point = map.project(f.geometry.coordinates as [number, number]);
            return [point.x, point.y];
        });
        const distances = points.map((p) => l2Distance(cursor, p));
        return min(distances);
    });
};

export type HoverSelect = ReturnType<typeof useHoverSelect>;
export const useHoverSelect = (map: RefToMap) => {
    const [hovered, setHovered] = useState<MapFeature | undefined>();
    const onMove = useThrottledCallback(
        (e: MapLayerMouseEvent) => {
            if (!map.current?.isStyleLoaded()) return;

            const layers = map.current
                .getStyle()
                .layers.map((l) => l.id)
                .filter((id) => isGeolocationLayerName(id));

            let feature: MapboxGeoJSONFeature | undefined;
            const {
                features,
                point: { x, y },
            } = e;

            if (features?.length) {
                // There may be multiple features at the cursor. Select the first one.
                [feature] = features;
            } else {
                const nearby = findNearestFeatures({ map: map.current, x, y, layers });
                if (nearby.length) [feature] = nearby;
            }

            const hovered = feature ? { feature: cloneFeature(feature), x, y } : undefined;
            setHovered(hovered);
        },
        [],
        100,
    );

    const [selection, setSelection] = useState<MapFeature | undefined>();
    const onClick = useCallback(
        (e: MapLayerMouseEvent) => {
            // Select the hovered feature unless it is already selected and there is
            // another feature that has been clicked. In that case, select and hover
            // the next clicked feature. This allows cycling through features by
            // clicking.
            let feature = hovered?.feature;
            const clicked = getFeaturesFromEvent(e)?.features ?? [];

            const isHoveredSelected = feature?.id && selection?.feature.id === feature.id;
            const hoveredIndexInClicked = clicked.findIndex((c) => c.id === feature?.id);
            const clickedIndexAfterHovered = (hoveredIndexInClicked + 1) % clicked.length;

            if (isHoveredSelected && !Number.isNaN(clickedIndexAfterHovered))
                feature = clicked[clickedIndexAfterHovered];

            if (feature) {
                setHovered({ feature, x: e.point.x, y: e.point.y });
                setSelection({ feature, x: e.point.x, y: e.point.y });
            } else {
                setSelection(undefined);
            }
        },
        [hovered?.feature, selection?.feature],
    );

    return { hovered, selection, onMove, onClick };
};

// set feature state for interactions
// sync annotation changes to map layer
/** Syncs map feature state with the store's hovered and selected objects */
export const useInteractionFeatureState = ({hovered, selection}: HoverSelect) => {
  const ref = useGeolocationMap()
  const interaction = useRef<{
    hovered: FeatureIdentifier | undefined
    selection: FeatureIdentifier | undefined
  }>({
    hovered: undefined,
    selection: undefined
  })

  useEffect(() => {
    const map = ref.current
    if (!map) return

    const checks = [
      {
        prev: interaction.current.hovered,
        current: getFeatureIdentifier(hovered?.feature),
        interactionField: 'hovered',
        stateField: 'hover'
      },
     {
        prev: interaction.current.selection,
        current: getFeatureIdentifier(selection?.feature),
        interactionField: 'selection',
        stateField: 'select'
      }
    ]

    for (const { stateField, interactionField, current, prev } of checks) {
      // console.log({ stateField, interactionField, current, prev })
      set(interaction.current, interactionField, current)
      if (isEqual(prev, current)) continue
      if (prev) map.setFeatureState(prev, { [stateField]: false })
      if (current) map.setFeatureState(current, { [stateField]: true })
    }
  }, [hovered?.feature, ref, selection])
}

const getFeatureIdentifier = (feature?: FeatureIdentifier) => {
    if (!feature) return
    const { id, source, sourceLayer } = feature
    return { id, source, sourceLayer }
  }
