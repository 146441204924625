import { FeatureIdentifier } from 'mapbox-gl';
import { useEffect, useRef } from 'react';
import { GEOLOCATION_LAYER_NAMES } from '../consts';
import { useGeolocationContext } from '../geolocation-context';
import { Camera, GeolocationAsset } from '../geolocation-task';
import { useGeolocationMap } from './use-geolocation-map';

export const useTrackCurrentFrameObjects = (camera: Camera | undefined, assets: GeolocationAsset[]) => {
    const {current: currentMap} = useGeolocationMap();
    const previousFrame = useRef<FeatureIdentifier[]>([]);
    const {isMapStyleLoaded} = useGeolocationContext()

    useEffect(() => {
        if (!currentMap || !isMapStyleLoaded)
            return;

        // Compare the current frame objects with the previous frame objects.
        // For each new object, set 'currentFrame' to true. For each old object,
        // set it to false.
        const currentFrame: FeatureIdentifier[] = [];
        if (camera?.id) {
            currentFrame.push({ id: camera?.id ?? undefined, source: GEOLOCATION_LAYER_NAMES.camera });
        }
        currentFrame.push(...assets.map((a) => ({ id: a.id ?? undefined, source: GEOLOCATION_LAYER_NAMES.assets })));
        currentFrame.push(
            ...assets.map((a) => ({ id: a.id ?? undefined, source: GEOLOCATION_LAYER_NAMES.modifiedAssets }))
        );

        currentFrame
            .filter((o) => !previousFrame.current.find((x) => x.id === o.id))
            .forEach((newObject) => {
                currentMap.setFeatureState(newObject, { currentFrame: true });
            });

        previousFrame.current
            .filter((o) => !currentFrame.find((x) => x.id === o.id))
            .forEach((oldObject) => {
                currentMap.setFeatureState(oldObject, { currentFrame: false });
            });

        previousFrame.current = currentFrame;
    }, [assets, camera?.id, currentMap, isMapStyleLoaded]);
};
