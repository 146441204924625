import { Job as CvatJob, ObjectState } from 'cvat-core-wrapper';
import { AnyAction, Dispatch } from 'redux';
import { z } from 'zod';
import { Label, LabelInstance, TypedAttributeMap, TypedLabel, extractLabelInstances } from './model';

export type ParseableTask<T extends CyvlTask> = {
    parse(jobRaw: unknown): T;
    safeParse(jobRaw: unknown): z.SafeParseReturnType<any, T>;
};

export abstract class CyvlTask {
    constructor(readonly labels: Label[]) {}

    protected states: ObjectState[] = [];
    protected activatedStateID: number | null = null;
    protected dispatch!: Dispatch<AnyAction>;
    job!: CvatJob;

    /** Called each render, observing redux state. */
    onRender({
        states,
        activatedStateID,
        dispatch,
        job,
    }: {
        states: ObjectState[];
        activatedStateID: number | null;
        dispatch: Dispatch<AnyAction>;
        job: CvatJob;
    }) {
        if (this.dispatch !== dispatch) this.dispatch = dispatch;
        if (this.job !== job) this.job = job;

        let needsUpdate = false;
        if (this.states !== states) {
            needsUpdate = true;
            this.states = states;
        }
        if (this.activatedStateID !== activatedStateID) {
            needsUpdate = true;
            this.activatedStateID = activatedStateID;
        }

        if (needsUpdate) {
            this.onUpdate();
        }
    }

    protected abstract onUpdate(): void;

    getFrameObjects = <L extends TypedLabel<TypedAttributeMap>>(label: L): LabelInstance<L, ObjectState>[] => {
        return extractLabelInstances(this.states, label, {
            hasLabel: (s, id) => s.label.id === id,
            getAttribute: (s, id) => s.attributes[id],
        });
    };
}
