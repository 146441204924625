import Icon from '@ant-design/icons';
import useResizeObserver from 'beautiful-react-hooks/useResizeObserver';
import { CarIcon, CircleIcon, CrosshairIcon } from 'icons';
import { Marker as MapboxMarker } from 'mapbox-gl';
import React, { ComponentType, FC, RefObject, useEffect, useRef, useState } from 'react';
import { MapProvider, Marker, MarkerProps, NavigationControl, Map as ReactMap, ScaleControl } from 'react-map-gl';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers';
import {
    ACTIVATED_OBJECT_COLOR,
    FOV_FILL_COLOR,
    FOV_STROKE_COLOR,
    GEOLOCATION_LAYER_NAMES,
    GEOLOCATION_MAP_ID,
    MAPBOX_TOKEN,
} from './consts';
import { FOVMarker } from './fov-marker';
import { GeolocationProvider } from './geolocation-context';
import { ControlContainer, JumpToSelectedFrame, ToggleCameraLayer } from './geolocation-controls';
import { GeolocationLayer } from './geolocation-layers';
import { Camera, GeolocationAsset, GeolocationTask } from './geolocation-task';
import {
    RefToMap,
    useGeolocationMap,
    useGeolocationTask,
    useTrackCurrentFrameObjects,
    useTrackMapStyleLoad,
} from './hooks';
import { useHoverSelect, useInteractionFeatureState } from './hover-select';
import { Coordinates, MapFeature } from './model';

export const MapComponent: FC = () => (
    <MapProvider>
        <GeolocationProvider>
            <Map />
        </GeolocationProvider>
    </MapProvider>
);

const useCenterFrame = (task: GeolocationTask, map: RefToMap) => {
    const { camera, bounds } = task;
    const [centeredFrame, setCenteredFrame] = useState(NaN);
    useEffect(() => {
        if (map.current && camera && camera.location && camera.frame !== centeredFrame) {
            const bearing = camera.fov?.bearing === undefined ? 0 : 90 - camera.fov.bearing;
            map.current.fitBounds(bounds, {
                animate: false,
                bearing,
                pitch: 0,
                maxZoom: 21,
                padding: 20,
            });
            setCenteredFrame(camera.frame);
        }
    }, [bounds, camera, centeredFrame, map]);
};

const useAutoselectAsset = (task: GeolocationTask) => {
    const { assets, camera } = task;
    const [autoSelectedFrame, setAutoSelectedFrame] = useState(NaN);
    useEffect(() => {
        if (camera && camera.frame !== autoSelectedFrame) {
            assets[0]?.onSelect();
            setAutoSelectedFrame(camera.frame);
        }
    }, [assets, autoSelectedFrame, camera]);
};

const useLockCamera = (camera?: Camera) => {
    useEffect(() => {
        if (camera && !camera.isLocked) camera.lock();
    }, [camera]);
};

const useResizeContainer = (containerRef: RefObject<HTMLDivElement>, ref: RefToMap) => {
    const size = useResizeObserver(containerRef);
    const map = ref.current;
    useEffect(() => {
        if (size?.width && size.height) map?.resize();
    }, [map, size?.height, size?.width]);
};

const useColors = () => {
    useEffect(() => {
        document.documentElement.style.setProperty('--activated-shape-color', ACTIVATED_OBJECT_COLOR);
    }, []);
};

export const Map = () => {
    const task = useGeolocationTask();
    const { assets, camera } = task;
    const map = useGeolocationMap();
    const containerRef = useRef(null);

    const hoverSelect = useHoverSelect(map);

    useInteractionFeatureState(hoverSelect);
    useColors();
    useResizeContainer(containerRef, map);
    useLockCamera(camera);
    useAutoselectAsset(task);
    useCenterFrame(task, map);
    useTrackMapStyleLoad();
    useTrackCurrentFrameObjects(camera, assets);

    return (
        <div ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative' }}>
            <ReactMap
                keyboard={false}
                onLoad={(e) => {
                    (window as any).mapbox = e.target;
                }}
                id={GEOLOCATION_MAP_ID}
                mapboxAccessToken={MAPBOX_TOKEN}
                maxPitch={60}
                mapStyle='mapbox://styles/roadgnar/clfwqfhu0000u01mr2x28rxnb'
                reuseMaps
                onClick={hoverSelect.onClick}
                onMouseMove={hoverSelect.onMove}
                interactiveLayerIds={Object.values(GEOLOCATION_LAYER_NAMES)}
                cursor={hoverSelect.hovered ? 'pointer' : 'auto'}
                initialViewState={task.defaultViewState}
            >
                <ScaleControl unit='imperial' position='bottom-right' maxWidth={300} />
                <NavigationControl />
                {camera && <CameraMarker camera={camera} />}
                {assets.map((a) => (
                    <AssetMarker key={a.id} {...a} />
                ))}
                <GeolocationLayer task={task} />
            </ReactMap>
            <HoverTooltip hovered={hoverSelect.hovered} />
            <ControlContainer>
                <JumpToSelectedFrame selection={hoverSelect.selection} />
                <ToggleCameraLayer />
            </ControlContainer>
        </div>
    );
};

const HoverTooltip: FC<{ hovered: MapFeature | undefined }> = ({ hovered }) => {
    const currentFrame = useSelector((state: CombinedState) => state.annotation.player.frame.number);

    if (!hovered || !hovered.feature.properties?.frame) return null;
    const frameId: number = hovered.feature.properties.frame;

    // Don't show the tooltip for the current frame
    if (frameId === currentFrame) return null;

    return (
        <div
            style={{
                position: 'absolute',
                top: hovered.y,
                left: hovered.x,
                pointerEvents: 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                borderRadius: '0.5rem',
                margin: '0.5rem',
                padding: '0.5rem',
                color: '#f0f0f0',
                whiteSpace: 'nowrap',
            }}
        >
            Frame: {frameId}
        </div>
    );
};

const CameraMarker: FC<{ camera: Camera }> = ({ camera: { location, color, fov } }) => {
    if (!location) return null;

    return (
        <>
            {fov && (
                <FOVMarker
                    {...location}
                    {...fov}
                    fillColor={FOV_FILL_COLOR}
                    strokeColor={FOV_STROKE_COLOR}
                    strokeWidth={0.3}
                />
            )}
            <GeolocationMarker
                markerProps={{
                    anchor: 'center',
                    rotation: fov?.bearing !== undefined ? 90 - fov.bearing : 0,
                    draggable: false,
                    style: { pointerEvents: 'none' },
                }}
                icon={CarIcon}
                lat={location.lat}
                lon={location.lon}
                color={color}
            />
        </>
    );
};

const AssetMarker: FC<GeolocationAsset> = ({ location, color, active, onSelect, update }) => {
    const [hovered, setHovered] = useState(false);

    if (!location) return null;

    return (
        <GeolocationMarker
            lat={location.lat}
            lon={location.lon}
            icon={active || hovered ? CrosshairIcon : CircleIcon}
            iconStyle={{
                width: '30px',
                padding: active || hovered ? '0px' : '10px',
                cursor: hovered && !active ? 'pointer' : undefined,
            }}
            markerProps={{
                anchor: 'center',
                rotationAlignment: 'viewport',
                style: { zIndex: active ? 10 : 1 },
            }}
            color={color}
            active={active}
            onMouseDown={onSelect}
            onFocus={() => setHovered(true)}
            onBlur={() => setHovered(false)}
            onChange={(location) => update(location)}
        />
    );
};
/**
 * A marker that shows the location of the attribute.
 */
const GeolocationMarker: FC<{
    lat: number;
    lon: number;
    color: string;
    icon?: ComponentType;
    iconStyle?: React.CSSProperties;
    markerProps?: Partial<MarkerProps>;
    active?: boolean;
    onChange?: (a: Coordinates) => void;
    onMouseDown?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
}> = ({ lat, lon, color, active, onChange, onFocus, onBlur, onMouseDown, ...props }) => {
    const ref = useRef<MapboxMarker>(null);
    const callbacksRef = useRef({ onFocus, onBlur, onMouseDown });
    callbacksRef.current = { onFocus, onBlur, onMouseDown };

    useEffect(() => {
        if (!ref.current || !callbacksRef.current) return () => {};
        let isFocused = false;

        const enter = () => {
            if (!isFocused) callbacksRef.current.onFocus?.();
            isFocused = true;
        };

        const leave = () => {
            if (isFocused) callbacksRef.current.onBlur?.();
            isFocused = false;
        };

        const down = () => callbacksRef.current.onMouseDown?.();

        const el = ref.current.getElement();
        el.addEventListener('mouseover', enter);
        el.addEventListener('mouseleave', leave);
        el.addEventListener('mousedown', down);

        return () => {
            el.removeEventListener('mouseover', enter);
            el.removeEventListener('mouseleave', leave);
            el.removeEventListener('mousedown', down);
        };
    }, []);

    const [dragState, setDragState] = useState<{ lat: number; lon: number } | null>(null);

    return (
        <Marker
            longitude={dragState?.lon ?? lon}
            latitude={dragState?.lat ?? lat}
            draggable
            ref={ref}
            rotationAlignment='map'
            onDrag={(e) => setDragState({ lat: e.lngLat.lat, lon: e.lngLat.lng })}
            onDragEnd={(e) => {
                onChange?.({ lat: e.lngLat.lat, lon: e.lngLat.lng });
                setDragState(null);
            }}
            {...props.markerProps}
        >
            <Icon
                component={props.icon ?? CircleIcon}
                style={{
                    color: active ? ACTIVATED_OBJECT_COLOR : color,
                    display: 'block',
                    width: '30px',
                    ...props.iconStyle,
                }}
            />
        </Marker>
    );
};
