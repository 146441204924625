import { useEffect } from 'react';
import { useGeolocationContext } from '../geolocation-context';
import { useGeolocationMap } from './use-geolocation-map';

export const useTrackMapStyleLoad = () => {
    const map = useGeolocationMap();
    const currentMap = map.current;

    const { setIsMapStyleLoaded } = useGeolocationContext();

    useEffect(() => {
        console.debug('[StyleLoad Effect] Starting style load monitoring');
        if (!currentMap) {
            console.debug('[StyleLoad Effect] Early return: No map reference');
            return;
        }
        if (currentMap.isStyleLoaded()) {
            console.debug('[StyleLoad Effect] Style already loaded');
            setIsMapStyleLoaded(true);
            return;
        }

        const callback = () => {
            if (currentMap.isStyleLoaded()) {
                console.debug('[StyleLoad Effect] Style loaded successfully');
                setIsMapStyleLoaded(true);
                unsubscribe();
            }
        };

        const unsubscribe = () => {
            console.debug('[StyleLoad Effect] Cleaning up style data listener');
            currentMap.off('idle', callback);
        };

        console.debug('[StyleLoad Effect] Adding style data listener');
        currentMap.on('idle', callback);
        return unsubscribe;
    }, [currentMap, setIsMapStyleLoaded]);
};
