import { z } from 'zod';
import { Attribute, Label } from './cvat';
import { parseJSONObjectOrNull } from './util';

export const parseTypedAttributeName = (name: string) => {
    const match = name.match(/^([\w]+): ([\w]+)$/);
    if (!match) return;
    return { name: match[1], type: match[2] };
};

export const createTypedAttributeName = <TypeName extends string>(typeName: TypeName) =>
    z.string().transform((name, ctx) => {
        const match = parseTypedAttributeName(name);
        if (!match) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message:
                    'Invalid attribute name, must be in the form "name: Type" and use characters [a-zA-Z0-9_] only.',
            });
            return z.NEVER;
        }
        const res = { name: match.name, type: match.type as TypeName };
        if (res.type !== typeName) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Invalid attribute type, must be ${typeName}`,
            });
            return z.NEVER;
        }
        return res;
    });

export const createTypedAttribute = <TypeName extends string, ValueSchema extends z.ZodTypeAny>(
    typeName: TypeName,
    valueSchema: ValueSchema,
    format: (value: z.infer<ValueSchema>) => string,
) => {
    const TypedAttribute = Attribute.extend({
        name: createTypedAttributeName(typeName),
        inputType: z.literal('text'),
    });

    return {
        Name: typeName,
        Attribute: TypedAttribute,
        Value: valueSchema,
        parseValueObjectOrNull: (raw: unknown) => {
            const obj = parseJSONObjectOrNull(raw);
            if (!obj) return null;
            const parsed = valueSchema.safeParse(obj);
            if (!parsed.success) return null;
            return parsed.data;
        },
        format,
    };
};

export type TypedAttributeDefinition = ReturnType<typeof createTypedAttribute>;
export type TypedAttribute = z.infer<TypedAttributeDefinition['Attribute']>;
export type TypedAttributeWithSchema<T extends TypedAttributeDefinition> = TypedAttribute & { schema: T['Value'] };
export type TypedAttributeMap = Record<string, TypedAttribute & { schema: z.ZodTypeAny }>;
export type TypedAttributeValues<R extends TypedAttributeMap> = { [K in keyof R]: z.infer<R[K]['schema']> | undefined };
export type TypedValuesForLabel<T> = T extends TypedLabel<infer R> ? TypedAttributeValues<R> : never;
export type TypedLabel<R extends TypedAttributeMap> = Label & { types: R };
export type TypedLabelAny = TypedLabel<TypedAttributeMap>;
export type TypedAttributeValue<T extends TypedAttributeDefinition> = z.infer<T['Value']>;
export type TypedMapForLabel<T> = T extends TypedLabel<infer R> ? R : never;
