export const ACTIVATED_OBJECT_COLOR = '#ff0000';
export const FOV_FILL_COLOR = 'rgba(0, 187, 174, 0.4)';
export const FOV_STROKE_COLOR = 'rgba(0, 187, 174, 0.8)';
export const LIDAR_RELATED_IMAGE_COLORS = ['#6cd32c', '#2cc0d3', '#932cd3', '#d33f2c'];
export const MAPBOX_TOKEN =
    'pk.eyJ1Ijoicm9hZGduYXIiLCJhIjoiY2tsb3BjanA4MHY2eDJ5cXBjdXNndDJyOCJ9.8gLZYamV12YMA5sOmOALCg';

export const GEOLOCATION_MAP_ID = 'geolocation-map';

export const GEOLOCATION_LAYER_NAMES = {
    camera: 'camera',
    assets: 'assets',
    modifiedAssets: 'modified-assets',
};

const layersByName = Object.fromEntries(Object.entries(GEOLOCATION_LAYER_NAMES).map(([k, v]) => [v, k]));
export const isGeolocationLayerName = (name: string) => name in layersByName;

export const isHoverOrSelected = [
    'any',
    ['boolean', ['feature-state', 'select'], false],
    ['boolean', ['feature-state', 'hover'], false],
];

export const isCurrentFrame = ['boolean', ['feature-state', 'currentFrame'], false];
export const isModified = ['boolean', ['feature-state', 'modified'], false];
