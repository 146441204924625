import { FeatureCollection } from 'geojson';
import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { GEOLOCATION_LAYER_NAMES, isCurrentFrame, isHoverOrSelected, isModified } from './consts';
import { useGeolocationContext } from './geolocation-context';
import { GeolocationTask } from './geolocation-task';
import { useGeolocationLayers } from './hooks';

// Main component that renders all geolocation layers
export const GeolocationLayer: React.FC<{ task: GeolocationTask }> = ({ task }) => {
    const { cameraLayer, completeLayer, modifiedLayer } = useGeolocationLayers(task);

    return (
        <>
            <CameraLayer data={cameraLayer} />
            <AssetLayer completeLayer={completeLayer} modifiedLayer={modifiedLayer} />
        </>
    );
};

// Render camera layer
const CameraLayer: React.FC<{ data: FeatureCollection }> = ({ data }) => {
    const { showCameraLayer } = useGeolocationContext();

    return (
        <Source id={GEOLOCATION_LAYER_NAMES.camera} type='geojson' data={data}>
            <Layer
                id={GEOLOCATION_LAYER_NAMES.camera}
                type='circle'
                layout={{
                    visibility: showCameraLayer ? 'visible' : 'none',
                }}
                paint={{
                    'circle-radius': ['case', isHoverOrSelected, 7, 4],
                    'circle-opacity': ['case', isCurrentFrame, 0, isHoverOrSelected, 0.8, 0.4],
                    'circle-color': ['get', 'color'],
                }}
            />
        </Source>
    );
};

// Render asset layer with modified and complete sublayers
const AssetLayer: React.FC<{ completeLayer: FeatureCollection; modifiedLayer: FeatureCollection }> = ({
    completeLayer,
    modifiedLayer,
}) => {
    return (
        <>
            {/* Complete layer (with hidden modified features) */}
            <Source id={GEOLOCATION_LAYER_NAMES.assets} type='geojson' data={completeLayer}>
                <Layer
                    id={GEOLOCATION_LAYER_NAMES.assets}
                    type='circle'
                    paint={{
                        'circle-radius': ['case', isHoverOrSelected, 7, 4],
                        'circle-opacity': ['case', isModified, 0, isCurrentFrame, 0, isHoverOrSelected, 0.9, 0.5],
                        'circle-color': ['get', 'color'],
                    }}
                />
            </Source>

            {/* Modified layer */}
            <Source id={GEOLOCATION_LAYER_NAMES.modifiedAssets} type='geojson' data={modifiedLayer}>
                <Layer
                    id={GEOLOCATION_LAYER_NAMES.modifiedAssets}
                    type='circle'
                    paint={{
                        'circle-radius': ['case', isHoverOrSelected, 7, 4],
                        'circle-opacity': ['case', isCurrentFrame, 0, isHoverOrSelected, 0.9, 0.5],
                        'circle-color': ['get', 'color'],
                    }}
                />
            </Source>
        </>
    );
};
