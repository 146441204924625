import { CombinedState, JobPlugin } from 'reducers';
import { Dispatch } from 'redux';
import { LidarAnnotationTask } from './lidar-annotation-task';

export class LidarImageryPlugin implements JobPlugin {
    currentJobId?: number;
    task?: LidarAnnotationTask;

    constructor(private readonly dispatch: Dispatch) {}

    globalStateDidUpdate(state: CombinedState): void {
        const { job } = state.annotation;
        if (job.instance?.id !== this.currentJobId) {
            this.onJobChanged(job)
        }

        this.task?.onRender({
            states: state.annotation.annotations.states,
            activatedStateID: state.annotation.annotations.activatedStateID,
            dispatch: this.dispatch,
            job: job.instance,
        });
    }

    private onJobChanged(job: any): void {
        this.currentJobId = job.instance?.id;
        this.task = undefined

        if (!job.instance) return

        const parsed = LidarAnnotationTask.safeParse(job);
        if (!parsed.success) {
            console.debug('Not a lidar task', { job, issues: parsed.error.issues });
        } else {
            this.task = parsed.data;
        }
    }

    async getRelatedImagery(): Promise<Record<string, ImageBitmap>> {
        const result = await this.task?.getRelatedImagery();
        return result ?? {};
    }

    getRelatedImageryCount(): number | undefined {
        return this.task?.relatedImagery?.images.length;
    }
}
