import { pluginActions } from 'actions/plugins-actions';
import { ComponentBuilder } from 'components/plugins-entrypoint';
import { DimensionType } from 'cvat-core-wrapper';
import { ComponentType } from 'react';
import { CombinedState } from 'reducers';
import { CanvasPluginImpl } from './canvas-plugin';
import { FOVOverlay } from './fov-overlay';
import { FOVToggle } from './fov-toggle';
import { MapComponent } from './geolocation-map';
import { GeolocationTask } from './geolocation-task';
import { LidarAnnotationTask } from './lidar-annotation-task';
import { LidarImageryPlugin } from './lidar-imagery-plugin';

interface GeoPlugin {
    name: string;
    description: string;
    cvat: {};
    data: {
        core: any;
    };
    components: {
        map: ComponentType;
        fovOverlay: ComponentType;
    };
}

const geoPlugin: GeoPlugin = {
    name: 'Geolocation',
    description: 'Handles geolocation tasks',
    cvat: {},
    components: {
        map: MapComponent,
        fovOverlay: FOVOverlay,
    },
    data: {
        core: null,
    },
};

export const builder: ComponentBuilder = ({ core, dispatch }) => {
    geoPlugin.data.core = core;

    const canvasPlugin = new CanvasPluginImpl();
    const lidarPlugin = new LidarImageryPlugin(dispatch);

    dispatch(
        pluginActions.addUIComponent('annotationPage.canvas.panels', geoPlugin.components.map, {
            shouldBeRendered: (p, _) => {
                const props = p as { type: DimensionType; job: unknown } | undefined;
                if (props?.type === DimensionType.DIMENSION_3D || !props?.job) {
                    console.debug('Not rendering geolocation', props);
                    return false;
                }

                const parsed = GeolocationTask.safeParse(props.job);
                if (!parsed.success)
                    console.debug('Not a geolocation task', { job: props.job, issues: parsed.error.issues });
                return parsed.success;
            },
        }),
    );

    dispatch(
        pluginActions.addUIComponent('annotationPage.canvas.overlays', geoPlugin.components.fovOverlay, {
            shouldBeRendered: (p, _) => {
                const props = p as { job: unknown } | undefined;
                if (!props?.job) {
                    console.debug('Not FOV overlay', props);
                    return false;
                }

                const parsed = LidarAnnotationTask.safeParse(props.job);
                if (!parsed.success) console.debug('Not a lidar task', { job: props.job, issues: parsed.error.issues });
                return parsed.success;
            },
        }),
    );

    dispatch(pluginActions.addUIComponent('annotationPage.canvas.contextImages', FOVToggle));

    dispatch(pluginActions.setCanvasPlugin(canvasPlugin));
    dispatch(pluginActions.setJobPlugin(lidarPlugin));

    return {
        name: geoPlugin.name,
        destructor: () => {},
        globalStateDidUpdate: (state: CombinedState) => {
            lidarPlugin.globalStateDidUpdate(state);
        },
    };
};
