import { MutableRefObject, useRef } from 'react';
import { MapRef, useMap } from 'react-map-gl';
import { GEOLOCATION_MAP_ID } from '../consts';


export type RefToMap = MutableRefObject<MapRef | undefined>;
export const useGeolocationMap = (): RefToMap => {
    const map = useMap()[GEOLOCATION_MAP_ID];
    const ref = useRef<MapRef>();
    ref.current = map;
    return ref;
};
