import React, { useEffect } from 'react';
import { useLidarAnnotationTask } from './hooks';
import { RelatedImagery } from './lidar-annotation-task';
import { AnnulusSegment, Dot } from './svg-elements';

const useLockImagery = (imagery?: RelatedImagery) => {
    useEffect(() => {
        if (imagery && !imagery.isLocked) imagery.lock();
    }, [imagery]);
};

export const FOVOverlay: React.FC = () => {
    const relatedImagery = useLidarAnnotationTask()?.relatedImagery;

    useLockImagery(relatedImagery);

    if (!relatedImagery) return null;

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                position: 'relative',
            }}
        >
            {relatedImagery.images.flatMap(({ x, y, fov, color, name, isVisible }) => {
                if (!isVisible) return null;
                const dotRadius = 5;
                return [
                    <AnnulusSegment
                        key={`${name}-fov`}
                        fillColor='#ffffff'
                        fillOpacity={0.2}
                        strokeColor='#ffffff'
                        strokeOpacity={0.7}
                        strokeWidth={3}
                        fov={fov.fov}
                        near={fov.near}
                        far={fov.far}
                        originX={x}
                        originY={y}
                        originRotation={fov.bearing}
                    />,
                    <Dot
                        key={`${name}-dot`}
                        fillColor={color}
                        radius={dotRadius}
                        centerX={x}
                        centerY={y}
                        strokeWidth={2}
                        strokeColor='#ffffff'
                    />,
                ];
            })}
        </div>
    );
};
