import { changeFrameAsync } from 'actions/annotation-actions';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useGeolocationContext } from './geolocation-context';
import { MapFeature } from './model';

// Control container for map UI elements
export const ControlContainer: FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <div
            style={{
                position: 'absolute',
                top: '0.5rem',
                right: '3rem',
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                zIndex: 1,
            }}
        >
            {children}
        </div>
    );
};

// Jump to selected frame button
export const JumpToSelectedFrame: FC<{ selection: MapFeature | undefined }> = ({ selection }) => {
    const dispatch = useDispatch();
    const frameId: number | undefined = selection?.feature.properties?.frame;
    const disabled = frameId === undefined;
    return (
        <button
            type='button'
            style={{
                backgroundColor: disabled ? 'rgba(200, 200, 200, 0.8)' : 'rgba(255, 255, 255, 0.8)',
                padding: '0.25rem 0.5rem',
                borderRadius: '0.25rem',
                border: '1px solid #ccc',
            }}
            disabled={disabled}
            onClick={() => {
                if (!disabled) dispatch(changeFrameAsync(frameId));
            }}
        >
            {!disabled ? `Jump to frame ${frameId}` : 'No frame selected'}
        </button>
    );
};

// Toggle camera layer visibility
export const ToggleCameraLayer: FC = () => {
    const { showCameraLayer, setShowCameraLayer } = useGeolocationContext();

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                padding: '0.25rem 0.5rem',
                borderRadius: '0.25rem',
            }}
        >
            <label
                htmlFor='toggle-camera-layer'
                style={{
                    userSelect: 'none',
                    display: 'flex',
                    gap: '0.5rem',
                }}
            >
                <input
                    type='checkbox'
                    id='toggle-camera-layer'
                    checked={showCameraLayer}
                    onChange={(e) => setShowCameraLayer(e.target.checked)}
                />
                Show Camera Layer
            </label>
        </div>
    );
};
