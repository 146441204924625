import React, { FC, createContext, useContext, useState } from 'react';

// Map visibility context
export interface GeolocationContextType {
    showCameraLayer: boolean;
    setShowCameraLayer: (show: boolean) => void;
    isMapStyleLoaded: boolean;
    setIsMapStyleLoaded: (isLoaded: boolean) => void;
}

const GeolocationContext = createContext<GeolocationContextType>({
    showCameraLayer: false,
    setShowCameraLayer: () => {},
    isMapStyleLoaded: false,
    setIsMapStyleLoaded: () => {},
});

export const useGeolocationContext = () => useContext(GeolocationContext);

export const GeolocationProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [showCameraLayer, setShowCameraLayer] = useState(false);
    const [isMapStyleLoaded, setIsMapStyleLoaded] = useState(false);

    return (
        <GeolocationContext.Provider
            value={{ showCameraLayer, setShowCameraLayer, isMapStyleLoaded, setIsMapStyleLoaded }}
        >
            {children}
        </GeolocationContext.Provider>
    );
};
